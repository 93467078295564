<template>
  <div class="box">
          <!-- 面包屑 -->
          <div class="indexNews-nav">您的位置 :
            <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="">个人咨询</a></el-breadcrumb-item>
            <el-breadcrumb-item>{{ artInfo.title }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="indexNom-box">
              <div class="indexNom-title">{{pageData.title}}</div>
              <div class="indexNom-text" v-html="pageData.pageBody"></div>
          </div>
      
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
export default {
  data() {
    return {
        pageData:{},
        artInfo: {}
  };
    
  },
  methods: {
      // 个人咨询
      indexNomInfo(){
        let pageName = this.$route.query.pageName;
        // console.log(pageName)
         get("/index/pageinfo",{'pageName':pageName}).then((res)=>{
            console.log(res)
            this.pageData = res.data
        }).catch(err=>{
          console.log(err)
        })
      },
  },
  created() {},
  computed: {},
  mounted() {
    this.indexNomInfo()
  },
  
};
</script>

<style lang="less" scoped>
  * {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.indexNom-box{
    padding: 0 1rem;
}
.indexNews-nav{
    padding: 0 1rem;
}
// 面包屑
.indexNews-nav {
  padding: 0 1rem;
  width: 54rem;
  line-height: 3rem;
  display: flex;
  margin: 0 auto;
  font-size: .6rem;
  font-weight: 400;
  color: #6f7174;
}
.el-breadcrumb {
  line-height: 3rem;
  padding-left: .5rem;
  font-size: .6rem;
  font-weight: 400;
  // color: #6F7174;
  color: #4676f8;
}

.indexNom-title{
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
    padding: .6rem 0;
}
.indexNom-text{
    font-size: .7rem;
    font-weight: 400;
    color: #3B3D41;
}
</style>