<template>
  <div class="box">
    <!-- 轮播部分 -->
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="bannerItem in banners" :key="bannerItem.index">
          <img v-bind:src="bannerItem.imgUrl" style="width: 100%; height: 100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 中心内容 -->
    <div class="content">
      <!-- 按钮部分 -->
      <div class="btn" style="display:none">
        <div class="btn-top">
          <router-link :to="{
        path: '/indexNom',
        query: { pageName: 'personalconsultation' },
      }">
            <img src="../assets/home/icon-1.png" alt="" class="btn-list-info ">
          </router-link>
          <router-link to="">
            <img src="../assets/home/icon-3.png" alt="" class="btn-list-info ">
          </router-link>

        </div>
        <div class="btn-bottom">
          <router-link :to="{
        path: '/indexNom',
        query: { pageName: 'groupconsultation' },
      }">
            <img src="../assets/home/icon-2.png" alt="" class="btn-list-info ">
          </router-link>
          <router-link to="">
            <img src="../assets/home/icon-4.png" alt="" class="btn-list-info " @click="dialogFormVisible()">
          </router-link>
        </div>
      </div>
      <!-- 新闻公告 -->
      <div>
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/news-icon.png" alt=""> </div>
            <div class="news-left-title">新闻公告</div>
          </div>
          <div class="seeMore" @click="routerPush('/news')">查看更多</div>
        </div>
        <div v-for="newsListItem in newsList" :key="newsListItem.id"
          @click="routerPush(`/newsDetial/${newsListItem.id}`)" class="news-box">
          <div class="news-title-box">
            <el-tag plain class="news-tab">{{ newsListItem.cateName }}</el-tag>
            <div class="news-title">{{ newsListItem.title }}</div>
          </div>
          <div class="news-title-info">{{ newsListItem.summary }}</div>
        </div>
      </div>
      <!-- 产品介绍 -->
      <div v-if="activitys">
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/activity-icon.png" alt=""> </div>
            <div class="news-left-title">产品介绍</div>
          </div>
          <div class="seeMore" @click="routerPush('/acenter')">查看更多</div>
        </div>
        <div @click="toActivitiesList(activitys[0].id)">
          <div class="nactive-img">
            <img v-bind:src="activitys[0].imgUrl">
          </div>
          <div class="nactive-title">{{ activitys[0].title }}</div>
        </div>
      </div>
      <!-- 热门课程 -->
      <div>
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/hotClass-icon.png" alt=""> </div>
            <div class="news-left-title">热门课程</div>
          </div>
          <div class="seeMore" @click="routerPush('/course')">查看更多</div>
        </div>
        <div class="hotClass">
          <div class="hot-class-info" v-for="courseListItem in courseList" :key="courseListItem.id"
            @click="toClasses(courseListItem)">
            <div class="hot-class-info-img">
              <img v-bind:src="courseListItem.coverImg" />
            </div>
            <div class="hot-class-title">{{ courseListItem.courseTitle }}</div>
            <div class="hotClass-box">
              <div class="hot-class-icon">
                <img style="width:.7rem;" src="../assets/home/hot-icon.png" alt="">
              </div>
              <div class="hot-class-num"> {{ courseListItem.views }} </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 趣味测评 -->
      <div style="display:none">
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/fun-icom.png" alt=""> </div>
            <div class="news-left-title">趣味测评</div>
          </div>
          <div class="seeMore" @click="toFun()">查看更多</div>
        </div>
        <div class="fun-list" v-for="testingItem in testing" :key="testingItem.id" @click="toFunList(testingItem.id)">{{
        testingItem.title
      }}</div>
      </div>
      <!-- 心理自助 -->
      <div>
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/mind-icon.png" alt=""> </div>
            <div class="news-left-title">心理自助</div>
          </div>
          <div class="seeMore" @click="routerPush('/psyhelp')">查看更多</div>
        </div>
        <div class="psyhelp">
          <!-- 音频 -->
          <div class="psyhelp-box" v-for="mediaAudioListItem in mediaAudioList" :key="mediaAudioListItem.id"
            @click="toAudioClass(mediaAudioListItem)">
            <div class="psyhelp-img">
              <img v-bind:src="mediaAudioListItem.coverImg" />
            </div>
            <div class="psyhelp-title">{{ mediaAudioListItem.title }}</div>
          </div>
        </div>
        <!-- 视频 -->
        <div class="psyhelp-video-box" v-for="mediaVideoListItem in mediaVideoList" :key="mediaVideoListItem.id"
          @click="toVideoClass(mediaVideoListItem.playUrl)">
          <div class="psyhelp-video">
            <img v-bind:src="mediaVideoListItem.imgUrl" />
          </div>
          <div class="psyhelp-video-title">{{ mediaVideoListItem.subject }}</div>
        </div>
      </div>
      <!-- 心理美文 -->
      <div>
        <div class="news">
          <div class="news-left">
            <div class="news-left-icon"> <img src="../assets/home/paper-icon.png" alt=""> </div>
            <div class="news-left-title">心理美文</div>
          </div>
          <div class="seeMore" @click="routerPush('/psyprose')">查看更多</div>
        </div>
        <div>
          <div class="psyprose" v-for="psyproseArtsItem in psyproseArts" :key="psyproseArtsItem.id"
            @click="routerPush(`/newsDetial/${psyproseArtsItem.id}`)">
            <div class="psyprose-img">
              <img v-bind:src="psyproseArtsItem.imgUrl" />
            </div>
            <div class="psyprose-text">
              <div>
                <p class="psyprose-text-title">{{ psyproseArtsItem.title }}</p>
                <p class="psyprose-text-info">{{ psyproseArtsItem.summary }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 师资团队 -->
      <div>
        <div>
          <div class="news">
            <div class="news-left">
              <div class="news-left-icon"> <img src="../assets/home/teachers-icon.png" alt=""> </div>
              <div class="news-left-title">师资团队</div>
            </div>
            <div class="seeMore" @click="toTeacherTeam">查看更多</div>
          </div>
        </div>

        <div class="teacher-team">
          <div class="teacher" v-for="teachersItem in teachers" :key="teachersItem.id"
            @click="tabChange(teachersItem.id)">
            <img v-bind:src="teachersItem.teacherPhoto" />
          </div>
        </div>
        <div>
          <div v-for="teachersItem in teachers" :key="teachersItem.id" v-show="teachersItem.id == teachersItemShowId">
            <div class="teacher-title">{{ teachersItem.teacherName }} <span> {{ teachersItem.teacherTitle }}</span>
            </div>
            <div class="teacher-info">{{ teachersItem.introduce }}</div>
          </div>
        </div>

      </div>
      <!-- 案例分享 -->
      <div>
        <div>
          <div class="news">
            <div class="news-left">
              <div class="news-left-icon"> <img src="../assets/home/case-icon.png" alt=""> </div>
              <div class="news-left-title">案例分享</div>
            </div>
            <div class="seeMore" @click="routerPush('/cases')">查看更多</div>
          </div>
        </div>
        <div class="case-list" v-for="casesItem in cases" :key="casesItem.id" @click="toCasesList(casesItem.id)">
          {{ casesItem.title }}</div>
      </div>
    </div>
    <!-- 弹框 -->
    <!-- <van-popup v-model="menuShow" bind:close="onClose">
        <van-dialog id="van-dialog"  />
        <div class="dialog-box">
          <div class="dialog-img">
            <img src="../assets/home/submitOk.png" alt="">
          </div>
          <div class="dialog-title">提交成功</div>
          <div class="dialog-text">感谢你的反馈~</div>
          <button class="dialog-btn">关闭</button>
        </div>
    </van-popup> -->

    <!-- 弹框 -->
    <el-dialog title="意见箱" :visible.sync="dialogFormVisibleXiang" class="dialogBox" center width="15rem">
      <el-form :model="form" class="dialogList" ref="dialogList">
        <el-form-item label="姓名：" :label-width="formLabelWidth" :rules="[
        { required: true, message: '请输入姓名', trigger: 'blur' },
        {
          min: 2,
          max: 18,
          message: '长度在 2 到 18 个字符',
          trigger: ['blur', 'change'],
        },
      ]" prop="name">
          <el-input v-model="form.name" autocomplete="off" size="small"></el-input>
        </el-form-item>
        <el-form-item label="电话：" :rules="[
        { required: true, message: '请输入电话', trigger: 'blur' },
        {
          pattern: /^1[3|5|7|8|9|6]\d{9}$/,
          message: '请输入正确的手机号码格式',
          trigger: ['blur', 'change'],
        },
      ]" prop="contactPhone" :label-width="formLabelWidth">
          <el-input v-model.number="form.contactPhone" autocomplete="off" size="small" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="正文：" :rules="[
        {
          required: true,
          message: '请输入内容',
          trigger: ['blur', 'change'],
        },
      ]" prop="body" :label-width="formLabelWidth">
          <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea"> </el-input> -->
          <el-input type="textarea" v-model="form.body" placeholder="请输入内容" :rows="4" maxlength="1000"
            show-word-limit></el-input>
          <div class="inputText"> <span>*</span> 来信内容简明扼要，说清事实，原则上一信只反应一个问题，字数限制1000以内 </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()" round>确 定</el-button>
        <el-button @click="cancel()" round class="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 意见箱提交完成 -->
    <el-dialog title="提交成功" center :visible.sync="closeDialog" width="15rem" top="30vh">
      <!-- <div> <img src="../assets/home/submitOk.png" alt=""> </div> -->
      <span> &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
        &nbsp; &nbsp;&nbsp;&nbsp; 感谢您的反馈~</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
import request from "../utils/request";
export default {
  data () {
    return {
      closeDialog: false,
      dialog: false,
      newsList: [],
      activitys: [{}],
      banners: [],
      courseList: [],
      testing: [],
      psyproseArts: [],
      cases: [],
      mediaVideoList: [],
      teachers: [],
      mediaAudioList: [],
      menuShow: false,
      dialogFormVisibleXiang: false,
      form: {
        name: "",
        contactPhone: "",
        body: "",
        email: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "4rem",
      teachersItemShowId: 0
    };

  },
  mounted () {
    this.getList();
    this.getCourseList()
    this.getClassList()
    this.getListClass();
    let siteConfig = getItem("centerConfig");
    if (siteConfig) {
      document.title = siteConfig.centerName;
    }
  },
  methods: {
    tabChange (id) {
      this.teachersItemShowId = id
    },
    getCourseList () {//课程列表
      let data = {
        cateId: 0,
        pageNum: 1,
        count: 1,
      };
      request({
        url: "/course/lists",
        method: "post",
        data: data,
      }).then((res) => {
        this.courseList =res.data.lists
        this.total = res.data.count;
      })
    },
    getClassList (cateId) { // 请求心理自主列表
      get('/smallvideo/videos', { cateId: 0, page: 1,pageSize:6 })
        .then((res) => {
          this.mediaAudioList = res.data.lists
        })
    },
    // 数据请求
    getList () {
      get("/index/home")
        .then((res) => {
          this.newsList = res.data.newsList;
          this.activitys = res.data.activitys;
          this.banners = res.data.banners;
          // this.courseList = res.data.courseList;
          this.testing = res.data.testing;
          this.psyproseArts = res.data.psyproseArts;
          this.cases = res.data.cases;
          // this.mediaVideoList = res.data.mediaVideoList;
          // this.mediaAudioList = res.data.mediaAudioList;
        })
        .catch((err) => { });
    },
    getListClass () {
      get('/index/common')
        .then((res) => {
          this.cateList = res.data.cateList
          this.centerConfig = res.data.centerConfig
          this.newsList = res.data.newsList
          this.testing = res.data.testing
          this.cases = res.data.cases
          this.psyproseArts = res.data.psyproseArts
          this.banners = res.data.banners
          this.pages = res.data.pages
          this.activitys = res.data.activitys
          this.teachers = res.data.teachers;
          this.teachersItemShowId = this.teachers[0].id
        })
        .catch((err) => { })
    },
    // 跳转课程外部链接
    toClasses (e) {
      window.open(`https://res.x-lingtong.cn/courseDetail/${e.courseId}`, '_blank');
    },
    routerPush (path) {
      this.$router.push({ path });
    },
    toActivitiesList (artId) {   // 产品介绍
      this.$router.push({ path: `/newsDetial/${artId}` });
    },
    toCasesList (artId) {   // 案例分享详情页
      this.$router.push({ path: `/newsDetial/${artId}` });
    },
    toTeacherTeam () {    // 师资团队
      this.$router.push({ path: `/center?pageId=teachers`, params: { tabsActive: 'teachers' } });
    },
    toVideoClass (video) {
      window.open(video, "_blank");
    },
    toAudioClass (audio) {
      this.$router.push({ path: `/videoPlay?id=${audio.id}` })
    },
    dialogFormVisible () {
      this.dialogFormVisibleXiang = true
    },
    // 点击弹出框确认
    submit () {
      this.$refs["dialogList"].validate((valid) => {
        if (valid) {
          this.dialogFormVisibleXiang = false;
          if (this.form.name == "") {
            this.dialogFormVisibleXiang = true
          } else {
            let name = this.form.name;
            let contactPhone = this.form.contactPhone;
            let body = this.form.body;
            get("/misc/feedback", {
              name: name,
              contactPhone: contactPhone,
              body: body,
            })
              .then((res) => {
                this.dialogFormVisibleXiang = false;
                this.form.name = "";
                this.form.contactPhone = "";
                this.form.body = "";
                this.closeDialog = true;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          // alert("请补全信息")
          return false;
        }
      });
    },
    // 点击弹出框取消
    cancel () {
      this.dialogFormVisibleXiang = false;
      this.form.name = "";
      this.form.contactPhone = "";
      this.form.body = "";
    },
  },
  created () { },
  computed: {},

};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  background-color: #eee;
  // padding-top: 2rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// body {
//     width: 100vw;
//     overflow: hidden;
//     margin: 0;
// }
.van-dialog {
  border-radius: .6rem;
}

.dialog-img {
  position: absolute;
  left: 3rem;
}

.dialog-img>img {
  width: 4rem;

}

.dialog-box {
  width: 10rem;
  height: 10rem;
  background: #FFFFFF;
  border-radius: .6rem;
  position: relative;
}

.dialog-title {
  font-size: .9rem;
  padding-bottom: .6rem;
  font-weight: 600;
  color: #3B3D41;
  ;
  padding-top: 1.5rem;
}

.dialog-text {
  font-size: .8rem;
  font-weight: 400;
  color: #9B9DA2;
  padding-bottom: .6rem;
}

.dialog-btn {
  width: 3rem;
  font-size: .6rem;
  height: 1rem;
  background: #4676F8;
  border-radius: 1.35rem;
  border: none;
  color: white;
}

.dialog-title,
.dialog-text,
.dialog-btn {
  text-align: center;
  margin: 0 auto;
}

.active-text {
  color: blue;
}

.box {
  background: #ffffff;
  overflow: hidden;
}

// 轮播部分
.banner {
  // margin-top: 0.7rem;
  // padding-top: 0.7rem;
  margin-bottom: 1rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
}

.van-swipe-item {
  background: #fff !important;
}

.my-swipe {
  height: 10rem !important;
}

// 中心部分
.content {
  padding: 0 1.2rem;
  background-color: #fff;
}

.btn {
  display: flex;
  margin-bottom: 0.5rem;
}

.btn-top,
.btn-bottom {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding-right: .6rem;
}

.btn-top>a>img,
.btn-bottom>a>img {
  height: 2rem;
  margin-top: .5rem;
}

.btn-list-info {
  margin: 0rem;
  // width: 7rem;
  border-radius: .3rem;
  border: none;
  cursor: pointer;
  // padding-right: 1.7rem;
  width: 8rem;
}

// 新闻公告
.news {
  height: 2.8rem;
  line-height: 2.8rem;
  // margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.news-left {
  display: flex;
}

.news-left-title {
  font-size: .9rem;
  font-weight: bold;
  align-items: center;
}

.news-left-icon>img {
  // width: 1.5rem;
  // height: 1.5rem;
  // padding-right: .2rem;
  // padding-top: .5rem;
  width: 1.3rem;
  height: 1.3rem;
  padding-right: 0.2rem;
  padding-top: 0.6rem;
}

.seeMore {
  color: #6F7174;
  font-size: 1rem;
  font-size: .7rem;
}

.news-box {
  padding-top: 1rem;
}

.news-box:last-child {
  margin-bottom: 0.5rem;
}

.news-box:nth-child(2) {
  padding-top: 0.2rem;
}

.news-title-box {
  display: flex;
  // padding-top: 1rem;

}

// .news-title-box > span{
//   text-align: center;
//   display: block;
//   height: 1rem;
//   line-height: 1rem;
//   align-items: center;
//   border: .05rem solid #4676F8;
// }
.el-tag {
  height: 1rem;
  line-height: 1rem;
  border: .05rem solid #4676F8;
}

.van-tag--primary.van-tag--plain {
  background: rgba(70, 118, 248, 0.05);
}

.van-tag {
  width: 20%;
  padding: 0rem;
}

.news-title {
  // margin-left: .5rem;
  margin-left: 0.3rem;
  font-size: .8rem;
  font-weight: 600;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.news-title-info {
  font-size: .7rem;
  // padding-top: .9rem;
  padding-top: .4rem;
  color: #9B9DA2;
  line-height: 1rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.nactive-img>img {
  width: 100%;
  // height: 12rem; 
  background-color: #39a9ed;
  border-radius: .3rem;
}

.nactive-title {
  height: 2.25rem;
  font-size: .8rem;
  font-weight: 600;
  color: #3B3D41;
  line-height: 2.25rem;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.hotClass {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hot-class-info {
  width: 7.5rem;
  display: inline-block;
  margin-top: .5rem;
}

.hot-class-info:first-child {
  margin-top: 0rem;
}

.hot-class-info:nth-child(2) {
  margin-top: 0rem;
}

.hot-class-info:nth-child(5) {
  margin-bottom: 0.4rem;
}

.hot-class-info:nth-child(6) {
  margin-bottom: 0.4rem;
}

.hotClass-box {
  display: flex;
  width: 8.85rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #9B9DA2;
}

.hot-class-info-img>img {
  // margin-top : .6rem;
  width: 7.5rem;
  height: 4.8rem;
  border-radius: .3rem;
}

hot-class-icon>img {
  width: .7rem;
  height: .7rem;
}

.hot-class-title {
  width: 100%;
  height: 1rem;
  font-size: .65rem;
  font-weight: 600;
  color: #3B3D41;
  line-height: 1rem;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.hot-class-num {
  font-size: .65rem;
  color: #9B9DA2;
  line-height: 1.4rem;
  height: 1.4rem;
}

.fun-list {
  font-size: .8rem;
  margin-top: .6rem;
  font-weight: 400;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.psyhelp {
  display: flex;
  justify-content: space-between;
  // 溢出部分剪切
  overflow: hidden;
  // 设置横向滚动
  overflow-x: scroll;
  // 禁止纵向滚动
  overflow-y: hidden;
  // 设置文本平铺
  text-align: justify;
  box-sizing: border-box;
}

// 	/*隐藏滚动条*/
.psyhelp::-webkit-scrollbar {
  display: none;
}

.psyhelp-box {
  width: 10rem;
  // height: 9.5rem;
  height: 8.5rem;
}

.psyhelp-img>img {
  width: 5rem;
  height: 3.5rem;
  background: #D8D8D8;
  border-radius: .3rem;
  margin-right: 1rem;
  // margin-right: 0.6rem;
}

.psyhelp-title {
  font-size: .7rem;
  width: 5rem;
  padding-top: .8rem;
  // font-size: .8rem;
  color: #3B3D41;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.psyhelp-video-box {
  // padding-top: 1.6rem;
  padding-top: 1rem;
}

.psyhelp-video-box:last-child {
  margin-bottom: 1rem;
}

.psyhelp-video>img {
  width: 100%;
  // height: 10rem;
  border-radius: .3rem;
}

.psyhelp-video-title {
  font-size: .8rem;
  padding-top: .5rem;
  font-weight: 600;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.psyprose {
  display: flex;
  padding-top: .6rem;
}

.psyprose:first-child {
  padding-top: 0rem;
}

.psyprose:last-child {
  margin-bottom: .8rem;
}

.psyprose-img>img {
  width: 6.5rem;
  // height: 4rem;
  height: 100%;
  margin-right: .5rem;
  border-radius: .3rem;
}

.psyprose-text {
  display: flex;
  align-items: center;
}

.psyprose-text>div {
  width: 10rem;
}

.psyprose-text-title {
  font-size: .8rem;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
  font-weight: 600;
  color: #3B3D41;
}

.psyprose-text-info {
  font-size: .7rem;
  font-weight: 400;
  color: #9B9DA2;
  padding-top: .5rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// 师资团队
.teacher-team {
  display: flex;
  // justify-content: space-between;
  // 溢出部分剪切
  white-space: nowrap;
  overflow: hidden;
  /*设置横向滚动*/
  overflow-x: scroll;
  /*禁止纵向滚动*/
  overflow-y: hidden;
  /*文本平铺*/
  text-align: justify;
  /*设置边距改变效果为内缩*/
  box-sizing: border-box;
}

/*隐藏滚动条*/
.teacher-team::-webkit-scrollbar {
  display: none;
}

.teacher {
  display: flex;
}

.teacher>img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: .6rem;
}

.teacher-title>span {
  font-size: .7rem;
  color: #6F7174;
  padding-left: .5rem;
}

.teacher-title {
  font-size: .8rem;
  font-weight: 600;
  color: #3B3D41;
  margin-top: 1rem;
}

.teacher-info {
  padding-top: .5rem;
  font-size: .7rem;
  font-weight: 400;
  color: #3B3D41;
  line-height: 1rem;
  margin-bottom: 1rem;
}

// 案例分享
.case-list {
  font-size: .7rem;
  margin-top: .6rem;
  font-weight: 400;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.case-list:first-child {
  margin-top: 0rem !important;
}

.case-list:last-child {
  margin-bottom: 2rem;
}

.news-title {
  width: calc(100% - 3.7rem);
}

.news-tab {
  font-size: .6rem;
  white-space: nowrap;
}

.inputText {
  font-size: .6rem;
  color: #9B9DA2;
}

.inputText span {
  color: #FC6161;
}

.el-form-item__content>.inputText {
  line-height: 1rem;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 1.25rem 1.25rem 1.25rem .05rem !important;
}

.el-form-item__label {
  padding: 0;
  text-align: none;
}

// .el-button .el-button--default .is-round{
//   color: black !important;
// }
.cancel {
  color: #606266 !important;
}
</style>