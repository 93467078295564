import 'babel-polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()
require('es6-promise').polyfill()

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vant from 'vant';
import 'vant/lib/index.css';
// import './styles/reset.css';
// 解决移动端延迟300毫秒
// import fastClick from 'fastclick';
// fastClick.attach(document.body);
import 'amfe-flexible';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


Vue.config.productionTip = false

// 页面跳转到另一页面的最顶部
router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  window.scrollTo(0, 0);
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.use(vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

