<template>
  <div class="box">
    <div class="banner">
      <img src="../assets/news/banner.png" alt="" />
    </div>
    <div class="news-box">
      <!-- <div class="center-nav">
        <div class="center-nav-list" :class="{
          'center-nav-list': 'center-nav-list',
          'center-nav-list-active': tabsActive == 'newsMessage',
        }" @click="tabsActive = 'newsMessage'">新闻资讯</div>
        <div class="center-nav-list" :class="{
          'center-nav-list': 'center-nav-list',
          'center-nav-list-active': tabsActive == 'newsNotice',
        }" @click="tabsActive = 'newsNotice'">通知公告</div>
      </div> -->

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <!-- 新闻资讯 -->
        <div v-if="tabsActive == 'newsMessage'" class="learnCourse" style="overflow: auto;  width: 100%">
          <div class="activityCenter-list" v-for="artListItem in artList" :key="artListItem.index"
            @click="toNewsDetial(artListItem.id)">
            <div class="activityCenter-list-img">
              <el-image v-bind:src="artListItem.imgUrl" scroll-container=".learnCourse" lazy />
            </div>
            <div class="activityCenter-list-text">
              <div>
                <div class="activityCenter-list-text-title">{{ artListItem.title }}</div>
                <p class="activityCenter-list-text-info">{{ artListItem.summary }}</p>
                <div class="time">{{ artListItem.createdAt }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 通知公告 -->
        <div v-if="tabsActive == 'newsNotice'">
          <div class="activityCenter-box">
            <div class="activityCenter-list">
              <div class="activityCenter-list-img"></div>
              <div class="activityCenter-list-text">
                <div class="activityCenter-list-text-title">心理学：9大“为什么会让…</div>
                <p class="activityCenter-list-text-info">若无十足把握自己能瞒天过海，那么待人接物</p>
                <div class="time">2022-01-08 15:36</div>
              </div>
            </div>
          </div>
        </div>

      </van-list>
      <div class="no-content" v-if="!artList.length">
        <img src="../assets/home/no-content.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  data() {
    return {
      tabsActive: 'newsMessage',
      artList: [],
      page: 1,
      loading: false,
      finished: true,
      refreshing: false,
    }
  },
  methods: {
    onLoad() {
      this.page++;
      this.getNewsList();
    },
    // load() {
    //   if (this.page == -1) return
    //   this.page++
    //   this.getNewsList()
    // },
    // 请求文章列表
    getNewsList() {
      let cateName = this.$route.name
      get('/article/lists', { cateName: cateName, page: this.page })
        .then((res) => {
          // 下滑触底事件
          // if (res.data.artList == null) {
          //   this.page = -1
          //   return
          // }
          // this.artList.push(...res.data.artList, ...res.data.artList, ...res.data.artList, ...res.data.artList)
          //   this.artList = res.data.artList;

          if (res.data.artList.length == 0) {
            this.finished = true;
          } else {
            this.finished = false
          }
          if (this.page == 1) {
            this.artList = res.data.artList;
          } else {
            this.artList = this.artList.concat(res.data.artList);
          }
          this.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    toNewsDetial(artId) {
      this.$router.push({ path: `/newsDetial/${artId}` })
    },
  },
  created() { },
  computed: {},
  mounted() {
    this.getNewsList()
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.news-box {
  padding: 0 1rem;
}

.activityCenter-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.center-nav {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0 0 0;
}

.center-nav-list {
  font-size: 0.9rem;
  color: #9b9da2;
  font-weight: 600;
}

// 点击样式
.center-nav-list-active {
  height: 1.25rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #3b3d41;
  line-height: 1.25rem;
}

.learnCourse::-webkit-scrollbar {
  display: none;
}

.activityCenter-list {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.activityCenter-list:last-child {
  padding-bottom: 1.2rem;
}

.activityCenter-box:last-child {
  margin-bottom: 2.8rem;
}

.activityCenter-list-text {
  display: flex;
  align-items: center;
  width: 9rem;
  padding-left: 0.5rem;
}

.activityCenter-list-text>div {
  width: 100%;
}

.activityCenter-list-img>img {
  width: 7.1rem;
  height: 100%;
  border-radius: 0.3rem;
}

.activityCenter-list-text-title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #3b3d41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.activityCenter-list-text-info {
  font-size: 0.7rem;
  font-weight: 400;
  color: #9b9da2;
  padding-top: 0.3rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.time {
  width: 9.85rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: #9b9da2;
  padding-top: 0.3rem;
}

.no-content {
  text-align: center;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.el-image {
  min-width: 1rem;
  min-height: 0.05rem;
  display: block;
  border-radius: 0.3rem !important;
}
</style>