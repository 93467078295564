<template>

  <div class="box">
    <!-- 头部部分 -->
    <div class="title">
      <div class="left">
        <img v-bind:src="centerConfig.logoUrl" @click="toHome()" />
      </div>
      <div class="right" @click="menuClick">
        <img :src="menuImgSrc[menuShow ? 1 : 0]" alt="">
      </div>
    </div>
    <!-- 中心内容出口 -->
    <div style="min-height: 25rem;margin-top: 2.8rem">
      <router-view />
    </div>
    <!-- 脚部 -->
    <div class="foot-box">
      <div class="foot">
        <!-- <div class="foot-titleText">清华大学心理健康咨询教育中心</div> -->
        <div class="foot-info">
          <div class="foot-adress">
            <div class="address">地址：</div>
            <span>{{ centerConfig.centerAddr }}</span>
          </div>
          <div class="foot-phone"> 电话：<span><a :href="'tel:' + centerConfig.contcatPhone" style="color: #3B3D41">{{
              centerConfig.contcatPhone
          }}</a></span> </div>
          <div class="foot-email"> 邮箱：<span>{{ centerConfig.contcatMail }}</span> </div>
        </div>
        <div class="foot-code">
          <div>
            <div class="foot-code-img">
              <img v-bind:src="centerConfig.wxmpQrcode" />
            </div>
            <div class="foot-code-text foot-code-text1 ">X灵通小程序</div>
          </div>
          <div>
            <div class="foot-code-img">
              <img v-bind:src="centerConfig.wxQrcode" />
            </div>
            <div class="foot-code-text">X灵通公众号</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航弹框 -->
    <div class="fixed-nav" v-show="menuShow">
      <div class="bj" @click="navClose"></div>
      <div class="nav-content">
        <router-link to="/index">
          <div class="nav-item" @click="navClose">首页</div>
        </router-link>
        <router-link :to="'/' + cateListItem.url" v-for="cateListItem in event(cateList)" :key="cateListItem.cateId"
          @click="menuClickNav(url)">
          <div class="nav-item" @click="navClose">
            {{ cateListItem.cateName }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from "../utils/xajax";
export default {
  data() {
    return {
      menuShow: false,
      cateList: [],
      centerConfig: {},
      menuImgSrc: [
        require('../assets/home/nav-icon.png'),
        require('../assets/home/close.png'),
      ]
    };
  },

  methods: {
    // 数据请求
    getCateList() {
      get("/index/common")
        .then((res) => {
          this.cateList = res.data.cateList;
          this.centerConfig = res.data.centerConfig;
          setItem("centerConfig", res.data.centerConfig);
          this.firendLinks = res.data.firendLinks;
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    menuClick() {
      this.menuShow = !this.menuShow;
    },
    navClose() {
      this.menuShow = false;
    },
    menuClickNav(cateListItem) {
      console.log(cateListItem)
      this.$router.push("/cateListItem.url");
      console.log(cateListItem.url)
      this.navClose();
    },
    event: function (cateList) {
      return cateList.filter(function (cateListUrl) {
        return cateListUrl.url != 'testing'
      })
    },
    toHome() {
      this.$router.push({ path: "/index" });
    },
  },

  created() { },
  computed: {

  },
  mounted() {
    this.getCateList();
  },

};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  background-color: #eee;
  overflow: hidden;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.active-text {
  color: blue;
}

.box {
  background: #ffffff;
}

.title {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  background: #fff;
}

.title>.left {
  width: 7.1rem;
  line-height: 3.8rem;
  margin-left: 1.2rem;
}

.left>img {
  max-width: 4.1rem;
  line-height: 2.8rem;
  max-height: 2.8rem;
  padding: 0.5rem 0rem;
}

.right>img {
  padding-top: 1rem;
  width: 1rem;
  margin-right: 1.2rem;
}

// 轮播部分
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

.foot-box {
  padding: 0 1.2rem;
  background: #F7F7F7;
}

.foot-titleText {
  padding-top: 1.2rem;
  font-size: .8rem;
  font-weight: 600;
  color: #3B3D41;
}

.foot-info {
  padding-top: 1rem;
}

.foot-adress {
  display: flex;
}

.foot-adress,
.foot-phone,
.foot-email {
  font-size: .7rem;
  font-weight: 400;
  color: #3B3D41;
  margin-bottom: .6rem;
}

.foot-code {
  padding-top: 1.3rem;
  padding-bottom: 2.8rem;
  display: flex;
  justify-content: space-around;
}

.foot-code-img>img {
  width: 5rem;
  height: 5rem;
}

.foot-code-text {
  font-size: .7rem;
  height: .7rem;
  line-height: .7rem;
  padding-top: 1.2rem;
  color: #3B3D41;
  text-align: center;
}

.foot-code-text1 {
  margin-left: .5rem;
}

.fixed-nav {
  position: fixed;
  left: 0;
  top: 3rem;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.fixed-nav .bj {
  background: rgba(59, 61, 65, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.fixed-nav .nav-content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 102;
  background: #fff;
  width: 4.3rem;
  padding: 0.525rem 1.55rem;
}

.fixed-nav .nav-content .nav-item {
  line-height: 1.75rem;
  width: 100%;
  color: #3B3D41;
  font-size: .8rem;
  font-weight: 400;
}

.address {
  white-space: nowrap;
}
</style>