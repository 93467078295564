<template>
    <div class="box">
        <div class="video-box">
            <div class="getBack">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/psyhelp' }">自助资源</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="video-title">{{ videoDetail.title }}</div>
            <div class="video-header">
                <p>讲师：{{ videoDetail.lecturer }}</p>
                <p>观看次数：{{ videoDetail.viewCount }}</p>
                <p>时间：{{ videoDetailCreatedAt }}</p>
            </div>
            <div class="video-play">
                <div id="artplayer" class="video-player-box"></div>
            </div>
            <div class="viedoSummary" v-html="videoDetail.summary"></div>
        </div>
    </div>
</template>
<script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js"></script>
  <script>
  import { post, get } from '../utils/xajax'
  import Artplayer from 'artplayer';
  
  export default {
    data() {
      return {
        videoDetail: {},
        videoDetailCreatedAt: '',
        video: {},
        artplayer:null,
        srcImgUrl: ''
      }
    },
    //事件方法
    methods: {
      getClassList() {
        let id = this.$route.query.id
        get('/smallvideo/detail', { id: id })
          .then((res) => {
            let videoInfo = res.data.detail
            this.videoDetail = videoInfo
            this.videoDetailCreatedAt = res.data.detail.createdAt.slice(0, 10)
            this.video.url = res.data.detail.playUrl
            this.video.cover = res.data.detail.coverImg
            this.srcImgUrl = res.data.detail.coverImg
            this.artplayer = new Artplayer({
              container: '#artplayer',
              url: res.data.detail.playUrl,
              playbackRate: true,
              setting: true, // 设置 （倍速）
              fullscreen: true, //全屏
              fullscreenWeb: false, //网页全屏
              pip: false, //画中画
              autoplay: true, //自动播放
              poster: res.data.detail.coverImg, //封面图
              backdrop: true,//背景
              theme: '#fff', //进度条颜色
            });
            this.artplayer.on('ready', (...args) => {
                if(res.data.detail.mediaType == 2) {
                //   console.log('触发', `url('${this.srcImgUrl}');`)
                  this.artplayer.video.style.background = `url(${this.srcImgUrl}) no-repeat`
                //   console.log( this.artplayer.video)
                }
             });
          })
          .catch((err) => {
            console.log(err)
          })
      },
      getBack() {
        this.$router.push('/psyhelp')
      },
      handleFullscreen() {},
    },
    //接口 一进入页面就执行的
    mounted() {
      this.getClassList();
    },
    //注册子组件
    components: {},
    //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
    computed: {
      $video() {
        return this.$refs.vueMiniPlayer.$video
      },
    },
    //过滤器
    filters: {
      filtime(val) {},
    },
  }
  </script>
  
<style  lang="less" scoped>
* {
    text-decoration: none;
    margin: 0rem;
    padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
    margin: 0;
    padding: 0;
}

.topImg {
    display: flex;
    position: relative;
    margin-top: 2rem;
}

.topImg>img {
    width: 100%;
    height: 7.1rem;
}

.video-box {
    padding: 0rem 1.2rem;
}

.video-title {
    font-size: 1rem;
    font-size: 500;
    text-align: center;
    margin: 2.125rem 0 1.75rem;
}

.video-play {
    width: 100%;
}

.viedoSummary {
    font-size: .75rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    letter-spacing: 0.125rem !important;
    text-indent: 2rem; //首行缩进两字符
    text-align: justify;
}

.video-header {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.75rem;
}

.video-header>p {
    font-size: .65rem;
    color: #9b9da2;
}

/deep/._play-btn {
    width: 3rem !important;
    height: 3rem !important;
}

/deep/ ._fullscreen .svg {
    width: 1.5rem !important;
}

/deep/._vol svg {
    width: 1.6rem !important;
}

/deep/.vm-player {
    background: transparent !important; //颜色透明
    min-height: 100%
        /* 0/16 */
        !important;
}

/deep/.qun-base-controls {
    height: auto !important
        /* 30/16 */
    ;
}

/deep/._progress ._time-current {
    font-size: 1.5rem !important;
    margin-right: 0.3125rem
        /* 5/16 */
    ;
}

/deep/._time-amount {
    font-size: 1.5rem !important;
}

/deep/._slider-btn {
    width: 1rem !important;
    height: 1rem !important;
}

/deep/ ._slider {
    width: 100% !important
        /* 100/16 */
    ;
    height: 0.5rem !important;
    margin: 0 0.5rem !important;
}

/deep/._progress {
    padding: 0 1.5rem !important;
}

/deep/._vol {
    width: 3rem !important;
    height: 3rem !important;
}

/deep/._fullscreen {
    width: 1.6rem !important;
}

.getBack {
    // margin-top: 4.125rem;
    margin-top: 1rem;
    display: flex;
}

.getBack-icon>img {
    width: 1.875rem;
    height: 1.875rem;
}

/deep/.video-player-box {
    height: 10rem;
}

/deep/.art-video {
    // background-size: 36rem 19rem !important;
    background-size: 100% !important;
    object-fit: cover !important; //去掉视频黑边
}
</style>
  