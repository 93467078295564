<template>
  <div class="box">
    <!-- 面包屑 -->
    <div class="indexNews-nav">
      您的位置 :
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/${artInfo.catePath}` }">{{
            artInfo.cateName
        }}</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>{{ artInfo.title }}</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
    <div class="newsDetial-box">
      <div class="newsDetial-title">{{ artInfo.title }}</div>
      <div class="time">{{ artInfo.createdAt }}</div>
      <div class="border-bottom"></div>
      <div class="newsDetial-text" v-html="artInfo.artBody"> </div>
    </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
import { isEmpty } from "../utils/utils";
export default {
  data() {
    return {
      artInfo: {},
    };
  },
  methods: {
    getNewsInfo() {
      let articleId = this.$route.params.artId;
      get("/article/detail", { articleId: articleId })
        .then((res) => {
          this.artInfo = res.data;
          let siteConfig = getItem("centerConfig");
          document.title = this.artInfo.title + " - " + siteConfig.centerName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() { },
  computed: {},
  mounted() {
    this.getNewsInfo();
  },
};
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.newsDetial-box {
  padding: 0 1rem;
}

.newsDetial-title {
  font-size: .8rem;
  font-weight: 600;
  color: #3B3D41;
  margin-top: 3rem;
  padding-top: 0.7rem;
}

.newsDetial-text {
  font-size: .7rem;
  font-weight: 400;
  color: #3B3D41;
  // line-height: 1rem;
  padding-bottom: 2.8rem;
  // margin-top: 2rem;
}

.border-bottom {
  width: 46rem;
  height: .05rem;
  background: #e5eaf1;
}

.time {
  font-size: .6rem;
  font-weight: 400;
  color: #9B9DA2;
  padding-top: .6rem;
  padding-bottom: .6rem;
}

// 面包屑
.indexNews-nav {
  position: fixed;
  background-color: #fff;
  top: 2.7rem;
  padding: 0 1rem;
  // width: 54rem;
  width: 100%;
  line-height: 3rem;
  display: flex;
  margin: 0 auto;
  font-size: .6rem;
  font-weight: 400;
  color: #6f7174;
}

.el-breadcrumb {
  line-height: 3rem;
  padding-left: .5rem;
  font-size: .6rem;
  font-weight: 400;
  color: #4676f8;
}
</style>
<style>
.newsDetial-text>p>img {
  width: 17rem;
  height: 100%;
}

.newsDetial-text>p>span>img {
  width: 17rem;
  height: 100%;
}

.newsDetial-text>p>span>span>img {
  width: 17rem;
  height: 100%;
}
</style>