<template>
    <div class="box">
        <div class="banner">
            <img src="../assets/activityCenter/banner.png" alt="">
        </div>
        <div class="activityCenter-box">
            <div class="activityCenter-list" v-for="artListItem in artList" :key="artListItem.id">
                <div class="activityCenter-list-img">
                    <img v-bind:src="artListItem.imgUrl" />
                </div>
                <div class="activityCenter-list-text">
                    <div class="activityCenter-list-text-title">{{ artListItem.title }}</div>
                    <p class="activityCenter-list-text-info">{{ artListItem.summary }}</p>
                    <div class="time"> {{ artListItem.createdAt }} </div>
                </div>
            </div>
            <!-- <div class="activityCenter-list">
              <div class="activityCenter-list-img"></div>
              <div class="activityCenter-list-text">
                  <div class="activityCenter-list-text-title">心理学：9大“为什么会让…</div>
                  <p class="activityCenter-list-text-info">若无十足把握自己能瞒天过海，那么待人接物</p>
                  <div class="time"> 2022-01-08 15:36 </div>
              </div>
          </div> -->
            <div class="no-content" v-if="!artList.length">
                <img src="../assets/home/no-content.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
    data() {
        return {
            artList: []
        };

    },
    methods: {
        // 请求文章列表
        async getNewsList() {
            let cateName = this.$route.name;
            get("/article/lists", { cateName: cateName })
                .then((res) => {
                    console.log(res);
                    this.artList = res.data.artList;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() { },
    computed: {},
    mounted() {
        this.getNewsList();
        document.title = '活动中心 - ' + siteConfig.centerName;
    },
};
</script>

<style lang="less" scoped>
* {
    text-decoration: none;
    margin: 0rem;
    padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
    margin: 0;
    padding: 0;
}

.activityCenter-box {
    padding: 0 1rem;
}

.banner {
    margin-top: 0.7rem;
}

.banner>img {
    width: 100%;
}

.activityCenter-list {
    margin-top: 1.6rem;
    display: flex;
    justify-content: space-between;
}

.activityCenter-box:last-child {
    margin-bottom: 2.8rem;
}

.activityCenter-list-text {
    // margin-left: 1.5rem;
    // display: flex;
    align-items: center;
    width: 8rem;
}

.activityCenter-list-text>div {
    width: 100%;
}

.activityCenter-list-img>img {
    width: 8.1rem;
    height: 4.5rem;
    // height: 3.9rem;
    // height: 4.5rem;

    border-radius: .3rem;
}

.activityCenter-list-img {
    width: 8.1rem;
    height: 4.5rem;
    background: plum;
}

.activityCenter-list-text-title {
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
}

.activityCenter-list-text-info {
    font-size: .7rem;
    font-weight: 400;
    color: #9B9DA2;
    padding-top: .5rem;
    // 多行省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.time {
    width: 9.85rem;
    height: 1.5rem;
    font-size: .7rem;
    font-weight: 400;
    color: #9B9DA2;
    line-height: 1.5rem;
}

.no-content {
    text-align: center;
}

.no-content>img {
    width: 50%;
    height: 50%;
    margin-top: 3rem;
}
</style>