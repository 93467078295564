<template>
  <div class="box">
      <div class="banner">
          <img src="../assets/acenter/banner.png" alt="">
      </div>
      <div class="activityCenter-box" style="overflow:auto;max-height: 30rem;width:100%" v-infinite-scroll="load">
          <div class="activityCenter-list" v-for="artListItem in artList" :key="artListItem.id" @click="toNewsDetial(artListItem.id)">
              <div class="activityCenter-list-img">
                  <el-image v-bind:src="artListItem.imgUrl" scroll-container=".activityCenter-box" lazy/>
              </div>
              <div class="activityCenter-list-text">
                  <div>
                      <div class="activityCenter-list-text-title">{{artListItem.title}}</div>
                      <p class="activityCenter-list-text-info">{{ artListItem. summary }}</p>
                      <div class="time"> {{ artListItem.createdAt }} </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
  data() {
    return {
        artList:[]
  };
    
  },
  methods: {
    load(){
      if(this.page == -1) return;
      this.page++;
      this.getNewsList();
    },
    // 请求文章列表
    async getNewsList() {
      let cateName = this.$route.name;
      get("/article/lists", { cateName: cateName })
        .then((res) => {
          console.log(res);
          this.artList = res.data.artList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toNewsDetial(artId){
        this.$router.push({path:`/newsDetial/${artId}`})
    },
  },
  created() {},
  computed: {},
  mounted() {
      this.getNewsList();
  },
  
};
</script>

<style lang="less" scoped>
    * {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.activityCenter-box{
    padding: 0 1rem;
}
.banner{
    margin-top: 0.2rem;
}
.banner>img{
    width: 100%;
}
.activityCenter-list{
    margin-top: 1rem;
    display: flex;
}
.activityCenter-box:last-child{
    margin-bottom: 2.8rem;
}
.activityCenter-box::-webkit-scrollbar{
    display: none;
}
.activityCenter-list-text{
    align-items: center;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    width: 9rem;
}
.activityCenter-list-text>div{
    width: 100%;
}
.activityCenter-list-img>img{
    width: 7.1rem;
    height: 100%;
    border-radius: .3rem;
}
.activityCenter-list-img{
     width: 8.1rem;
     height: 4.5rem;
}
.activityCenter-list-text-title{
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
    overflow:hidden;  //超出的文本隐藏
    text-overflow:ellipsis;  //溢出用省略号显示
    white-space:nowrap;  //溢出不换行
}
.activityCenter-list-text-info{
    font-size: .7rem;
    font-weight: 400;
    color: #9B9DA2;
    padding-top: 0.3rem;
    // 多行省略号
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical; 
    -webkit-line-clamp:2; 
}
.time{
    width: 9.85rem;
    font-size: .6rem;
    font-weight: 400;
    color: #9B9DA2;
    padding-top: 0.3rem;
}
.el-image{
  width: 8rem;
  height: 4.5rem;
  display: block;
  border-radius: 0.3rem !important;
}

</style>