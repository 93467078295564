<template>
  <div id="app">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  computed: {},
  mounted() {
    window.addEventListener(
      "orientationchange" in window ? "orientationchange" : "resize",
      (function () {
        //判断是屏幕旋转还是resize
        function c() {
          var d = document.documentElement; //获取html元素
          var cw = d.clientWidth || 750;
          d.style.fontSize =
            20 * (cw / 375) > 40 ? 40 + "px" : 20 * (cw / 375) + "px";
        }
        c();
        return c;
      })(),
      false
    );
      // 当前是移动端端，跳转到pc端
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)==false) {
        window.location.href="https://xlt.x-lingtong.com/"
    }
  },
};
</script>

<style lang="less">
</style>
