import Vue from 'vue'
import VueRouter from 'vue-router'
import homeLayout from '../layout/homeLayout.vue'
import index from '../views/home.vue'
import center from '../views/center.vue'
import course from '../views/course.vue'
import psyprose from '../views/psyprose.vue'
import psyhelp from '../views/psyhelp.vue'
import videoPlay from '../views/videoPlay.vue'
import newsDetial from '../views/newsDetial.vue'
import activityCenter from '../views/activityCenter.vue'
import news from '../views/news.vue'
import cases from '../views/cases.vue'
import indexNom from '../views/indexNom.vue'
import acenter from '../views/acenter.vue'
import err404 from '../views/404.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeLayout,
    redirect: '/index', //路由重定向
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '北京心灵通科技有限公司'
        },
        component: index
      },
      {
        path: 'center',
        name: 'center',
        meta: {
          title: '公司概况-北京心灵通科技有限公司'
        },
        component: center
      },
      {
        path: 'course',
        name: 'course',
        meta: {
          title: '课程学习-北京心灵通科技有限公司'
        },
        component: course
      },
      {
        path: 'psyprose',
        name: 'psyprose',
        meta: {
          title: '心理美文-北京心灵通科技有限公司'
        },
        component: psyprose
      },
      {
        path: 'psyhelp',
        name: 'psyhelp',
        meta: {
          title: '心理短视频-北京心灵通科技有限公司'
        },
        component: psyhelp
      },
      {
        path: 'videoPlay',
        name: 'videoPlay',
        meta: {
          title: '心理短视频-北京心灵通科技有限公司'
        },
        component: videoPlay
      },
      {
        path: 'newsDetial/:artId',
        name: 'newsDetial',
        meta: {
          title: '新闻公告-北京心灵通科技有限公司'
        },
        component: newsDetial
      },
      {
        path: 'activityCenter',
        name: 'activityCenter',
        meta: {
          title: '活动中心-北京心灵通科技有限公司'
        },
        component: activityCenter
      },
      {
        path: 'news',
        name: 'news',
        meta: {
          title: '新闻公告-北京心灵通科技有限公司'
        },
        component: news
      },
      {
        path: 'cases',
        name: 'cases',
        meta: {
          title: '案例分享-北京心灵通科技有限公司'
        },
        component: cases
      },
      {
        path: 'indexNom',
        name: 'indexNom',
        component: indexNom
      },
      {
        path: 'acenter',
        name: 'acenter',
        meta: {
          title: '产品介绍-北京心灵通科技有限公司'
        },
        component: acenter
      },
    ]
  },
  {
    path: '/404',
    name: 'err',
    component: err404
  },
  {
    path: '*',
    redirect: '/404'
  }

]


const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      // console.log(111)
      return {
        x: 0,
        y: 0
      }
    }
  },


  mode: 'history',
  // base: '/m/',
  base: process.env.BASE_URL,
  routes
})

export default router
