<template>
  <div class="box">
    <div class="banner">
      <img src="../assets/psyhelp/banner.png" alt="" />
    </div>
    <div class="psyhelp-box">
      <div class="psyhelp-nav" :style="{ height: menuShow ? '2.225rem' : '100%' }">
        <div :class="{ 'psyhelp-nav-list': true, tabActive: tabActive == 0 }" @click="tabChange(0)"><span>全部 </span>
        </div>
        <div :class="{ 'psyhelp-nav-list': true, tabActive: tabActive == PsyhelpNavItem.cateId }"
          @click="tabChange(PsyhelpNavItem.cateId)" v-for="PsyhelpNavItem in PsyhelpNav" :key="PsyhelpNavItem.cateId">
          <span>{{
              PsyhelpNavItem.cateName
          }}</span>
        </div>
        <!-- 导航点击按钮切换 -->
        <!-- <div class="seeMore" @click="menuClick">
                 <img :src="menuImgSrc[menuShow?1:0]" alt="">
             </div> -->
      </div>
      <!-- 课程主体部分 -->
      <van-list @load="onLoad" v-model="loading" :finished="finished" finished-text="没有更多了">
        <div class="learnCourse" style="overflow: auto; width: 100%">
          <div class="learnCourse-list" v-for="PsyhelpListItem in PsyhelpList" :key="PsyhelpListItem.id"
            @click="toClasses(PsyhelpListItem.id)">
            <div class="learnCourse-list-img">
              <el-image v-bind:src="PsyhelpListItem.coverImg" scroll-container=".learnCourse" lazy />
            </div>
            <div class="learnCourse-list-title">{{ PsyhelpListItem.title }}</div>
          </div>
          <div class="no-content" v-if="!PsyhelpList.length">
            <img src="../assets/home/no-content.png" alt="" />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'

export default {
  data() {
    return {
      menuShow: false,
      tabActive: 0,
      PsyhelpList: [],
      PsyhelpNav: [],
      menuImgSrc: [require('../assets/psyhelp/pull-up.png'), require('../assets/psyhelp/pull-down.png')],
      cateId: 0,
      page: 1,
      total: 0,
      loading: false,
      finished: true,
      refreshing: false,
    }
  },
  methods: {
    onLoad() {
      this.page++
      this.getClassList();
    },
    load() {
      if (this.page == -1) return
      this.page++
      // this.getPsyhelList()
      this.getClassList()
    },
    menuClick() {
      this.menuShow = !this.menuShow
    },
    navClose() {
      this.menuShow = false
    },
    //   获取列表
    getPsyhelList() {
      get('/article/medialist', { page: this.page })
        .then((res) => {
          // 下滑触底事件
          if (res.data.artList == null) {
            this.page = -1
            return
          }
          this.PsyhelpList.push(...res.data.artList)
          //   this.PsyhelpList =  res.data.artList;
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取导航
    getPsyhelpNav() {
      get('/article/mediacates')
        .then((res) => {
          this.PsyhelpNav = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCourseNav() {// 请求心理自助导航栏目
      get('/smallvideo/category')
        .then((res) => {
          this.PsyhelpNav = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClassList() { // 请求心理自助列表
      get('/smallvideo/videos', { cateId: this.cateId, page: this.page })
        .then((res) => {
          // this.PsyhelpList.push(...res.data.lists)
          if (res.data.lists.length == 0) {
            this.finished = true;
          } else {
            this.finished = false
          }
          if (this.page == 1) {
            this.PsyhelpList = res.data.lists;
          } else {
            this.PsyhelpList = this.PsyhelpList.concat(res.data.lists);
          }
          this.total = res.data.count
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    tabChange(cateId) {
      this.cateId = cateId
      this.page = 1
      this.PsyhelpList = []
      this.tabActive = cateId
      this.getClassList(cateId)
    },
    toClasses(id) {
      // window.location.href = e
      // window.open(e, '_blank')
      this.$router.push({ path: `/videoPlay?id=${id}` })
    },
  },
  created() { },
  computed: {},
  mounted() {
    // this.getPsyhelList();
    // this.getPsyhelpNav()
    this.getCourseNav();
    this.getClassList()
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.psyhelp-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.psyhelp-nav {
  display: flex;
  // flex-wrap: wrap;
  position: relative;
  height: 100%;
  overflow: hidden;

  // 设置横向滚动
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: justify;
  box-sizing: border-box;
  white-space: nowrap;
}

.psyhelp-nav::-webkit-scrollbar {
  display: none;
}

.psyhelp-nav-list {
  padding: 0 0.2rem;
  font-weight: 400;
  color: #3b3d41;
  font-size: 0.8rem;
  border-radius: 0.7rem;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 2.225rem;
}

.psyhelp-nav-list span {
  padding: 0.2rem 0.45rem;
}

.tabActive span {
  color: #4676f8;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 0.05rem #4676f8;
  background: rgba(70, 118, 248, 0.05);
}



.learnCourse::-webkit-scrollbar {
  display: none;
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.3rem;
}

.learnCourse-list {
  margin-top: 0.9rem;
  width: 7.8rem;
}

.learnCourse-list:last-child {
  padding-bottom: 2.9rem;
}

// .learnCourse-list-img>img {
//   width: 7.5rem;
//   height: 5rem;
//   border-radius: 0.3rem;
// }

.learnCourse-list-img>.el-image {
  height: 4.4rem;
  border-radius: 0.3rem;
}

.learnCourse-list-title {
  padding-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #3b3d41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.learnCourse-list-text {
  padding-top: 0.5rem;
  font-weight: 400;
  color: #9b9da2;
  font-size: 0.7rem;
}

.seeMore {
  position: absolute;
  right: 0;
  line-height: 2.225rem;
}

.seeMore>img {
  width: 1rem;
  padding-top: 0.3rem;
}

.hot-class-icon {
  display: inline-block;
}

.hot-class-icon>img {
  width: 0.7rem;
  height: 0.7rem;
  padding-right: 0.2rem;
}

.fixed-nav {
  position: fixed;
  left: 0;
  top: 2.8rem;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.fixed-nav .bj {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.fixed-nav .nav-content {
  position: absolute;
  right: 0;
  top: 130px;
  z-index: 102;
  background: #fff;
  width: 100%;
}

.fixed-nav .nav-content .nav-item {
  line-height: 1.75rem;
  width: 100%;
  color: #3b3d41;
  font-size: 0.8rem;
  font-weight: 400;
}

.no-content {
  text-align: center;
  margin: 0 auto;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
}

.learnCourseBottomText {
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  color: #9b9da2;
}

.el-image {
  display: block;
  min-height: 0.05rem;
}
</style>