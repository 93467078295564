<template>
    <div class="box">
        <div class="banner">
            <img src="../assets/center/banner.png" alt="">
        </div>
        <div class="center-box">
            <div class="center-nav">
                <div class="center-nav-list" :class="{
                    'center-nav-list': 'center-nav-list',
                    'center-nav-list-active': tabsActive == 'centerinfo',
                }" @click="tabsActive = 'centerinfo'">公司概况</div>
                <div class="center-nav-list" :class="{
                    'center-nav-list': 'center-nav-list',
                    'center-nav-list-active': tabsActive == 'teachers',
                }" @click="tabsActive = 'teachers'">师资团队</div>
                <!-- <div class="center-nav-list" :class="{
              'center-nav-list': 'center-nav-list',
              'center-nav-list-active': tabsActive == 'schedule',
            }"
            @click="tabsActive = 'schedule'">值班表</div> -->
            </div>
            <div class="center-content" v-if="tabsActive == 'centerinfo'">
                <!-- <div class="center-title">{{centerInfo.pageName}}</div> -->
                <div class="center-info" v-html="centerInfo.pageBody"></div>
            </div>
            <!-- 师资团队 -->
            <div v-if="tabsActive == 'teachers'">
                <div>
                    <div class="teachers-nav" v-if="teachers.length != 0">
                        <div>校内</div>
                        <!-- {{teachers[0].typeText.length}} -->
                    </div>
                    <div class="teachers-text" v-for="teachersItem in teachers" :key="teachersItem.id"
                        v-show="teachersItem.teachertype == '1'">
                        <img class="teachers-img" v-bind:src="teachersItem.teacherPhoto"
                            style="float: left; clear: both;" align="center" />
                        <div class="teachers-name">{{ teachersItem.teacherName }}
                            <span>{{ teachersItem.teacherTitle }}</span>
                        </div>
                        <div class="teachers-info">{{ teachersItem.introduce }}</div>
                    </div>
                </div>
                <div>
                    <div class="teachers-nav" v-if="teachers.length != 0">
                        <div>校外</div>
                    </div>
                    <div class="teachers-text" v-for="teachersItem in teachers" :key="teachersItem.id"
                        v-show="teachersItem.teachertype == '2'">
                        <img class="teachers-img" v-bind:src="teachersItem.teacherPhoto"
                            style="float: left; clear: both;" align="center" />
                        <div class="teachers-name">{{ teachersItem.teacherName }}
                            <span>{{ teachersItem.teacherTitle }}</span>
                        </div>
                        <div class="teachers-info">{{ teachersItem.introduce }}</div>
                    </div>
                </div>
                <div>
                    <div class="teachers-nav teachers-nav3" v-if="teachers.length != 1">
                        <div>特聘</div>
                    </div>
                    <div class="teachers-text" v-for="teachersItem in teachers" :key="teachersItem.id"
                        v-show="teachersItem.teachertype == '3'">
                        <img class="teachers-img" v-bind:src="teachersItem.teacherPhoto"
                            style="float: left; clear: both;" align="center" />
                        <div class="teachers-name">{{ teachersItem.teacherName }}
                            <span>{{ teachersItem.teacherTitle }}</span>
                        </div>
                        <div class="teachers-info">{{ teachersItem.introduce }}</div>
                    </div>
                </div>
            </div>
            <!-- 值班表 -->
            <div class="schedule" v-else-if="tabsActive == 'schedule'" style="display:none">
                <div class="schedule-title">{{ schedule.pageName }}</div>
                <div class="schedule-info" v-html="schedule.pageBody"></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
    data() {
        return {
            tabsActive: "centerinfo",
            centerInfo: {},
            schedule: {},
            teachers: [],
            teachersOne: [],
            teachersTwo: [],
            teachersThree: []
        };
    },
    methods: {
        getInfo() {
            get("/index/center")
                .then((res) => {
                    console.log(res);
                    this.teacherTeamCopy = res.data.teachers;
                    this.teacherTeam = this.teacherTeamCopy.slice(0, 10);
                    this.schedule = res.data.schedule;
                    this.centerInfo = res.data.centerInfo;
                    this.teachers = res.data.teachers;
                    console.log(res.data.teachers)
                    let arr = res.data.teachers;
                    // if(res.data.teacher.teachertype)
                    if (Object.keys(arr).length > 0) {
                        Object.keys(arr).forEach(function (teachertype) {
                            console.log(arr[teachertype].teachertype)
                            // if (arr[teachertype].teachertype == 1) {
                            //     console.log('校内')
                            //     console.log(res.data.teachers)
                            //     // this.teachersOne = res.data.teachers;
                            //     this.teachers = res.data.teachers;
                            // } 
                            // else if (arr[teachertype].teachertype == 2) {
                            //     console.log('校外')
                            //     this.teachersTwo = res.data.teachers;
                            // } else if (arr[teachertype].teachertype == 3) {
                            //     console.log('特聘')
                            //     this.teachersThree = res.data.teachers;
                            // }
                        });
                    } else {
                        console.log("对象为空");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() { },
    computed: {},
    mounted() {
        if (this.$route.query.pageId) {
            this.tabsActive = this.$route.query.pageId
        }
        this.getInfo();
        // let siteConfig = getItem("centerConfig");
        // document.title = "中心概况 - " + siteConfig.centerName;
    },
};
</script>

<style lang="less" scoped>
* {
    text-decoration: none;
    margin: 0rem;
    padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
    margin: 0;
    padding: 0;
}

.center-box {
    padding: 0 1rem;
    padding-bottom: 2rem;
}

.banner {
    margin-top: 0.2rem;
}

.banner>img {
    width: 100%;
}

.center-nav {
    display: flex;
    justify-content: space-around;
    padding-top: 1rem;
}

.center-nav-list {
    font-size: .8rem;
    color: #9B9DA2;
    font-weight: 600;
}

// 点击样式
.center-nav-list-active {
    height: 1.25rem;
    font-size: .9rem;
    font-weight: 600;
    color: #3b3d41;
    line-height: 1.25rem;
}

.center-content {
    padding-top: 1.6rem;
}

.center-title {
    padding-bottom: 1.2rem;
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
}

.center-info {
    font-size: .7rem;
    font-weight: 400;
    color: #3B3D41;
    // padding-bottom: 2.8rem;
}

.schedule {
    padding-top: 1.6rem;
}

.schedule-title {
    text-align: center;
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
}

.schedule-info {
    padding-top: 1.2rem;
    text-align: center;
    font-size: .7rem;
    font-weight: 400;
    color: #3B3D41;
}

.teachers-nav {
    padding-top: 1.2rem;
}

.teachers-nav3 {
    padding-top: 0rem;
}

.teachers-nav>div {
    width: 4rem;
    // height: 2.8rem;
    font-size: .9rem;
    font-weight: 600;
    color: #3B3D41;
}

.teachers-text {
    padding-top: 1.6rem;
    font-weight: 600;
    color: #3B3D41;
    font-size: .8rem;
}


.teachers-text>img {
    width: 2.8rem;
    height: 2.8rem;
}

.teachers-name {
    width: 4.8rem;
    font-size: .8rem;
    font-weight: 600;
    color: #3B3D41;
    display: inline-block;
    width: calc(100% - 6rem);
    margin-top: .5rem;
    white-space: nowrap;
}

.teachers-name>span {
    width: 2.8rem;
    height: 2rem;
    padding-left: .3rem;
    font-size: .7rem;
    font-weight: 400;
    color: #6F7174;
    line-height: 2rem;
}

.teachers-info {
    font-size: .7rem;
    font-weight: 400;
    color: #3B3D41;
    // margin-top: 1.25rem;
    margin-top: 1rem;
}

.teachers-img {
    width: 5rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.teachers-info {
    font-weight: 400;
    color: #3B3D41;
    font-size: .7rem;
}
</style>

<style >
.center-info>p>img {
    width: 17rem;
}

.center-info>p>span>img {
    width: 17rem;
}

.schedule-info>p>img {
    width: 17rem;
}
</style>

