<template>
  <div class="box">
    <div class="banner">
      <img src="../assets/psyprose/banner.png" alt="">
    </div>
    <!-- v-infinite-scroll="load" -->
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="psyprose-box" style="overflow:auto;max-height: 30rem;width:100%">
        <div class="psyprose-list" v-for="artListItem in artList" :key="artListItem.id"
          @click="toNewsDetial(artListItem.id)">
          <div class="psyprose-list-img">
            <el-image v-bind:src="artListItem.imgUrl" scroll-container=".psyprose-box" lazy />
          </div>
          <div class="psyprose-list-text">
            <div>
              <div class="psyprose-list-text-title">{{ artListItem.title }}</div>
              <p class="psyprose-list-text-info">{{ artListItem.summary }}</p>
            </div>
          </div>
        </div>
        <div class="no-content" v-if="!artList.length">
          <img src="../assets/home/no-content.png" alt="">
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
  data() {
    return {
      artList: [],
      page: 1,
      loading: false,
      finished: true,
      refreshing: false,
    };
  },
  methods: {
    onLoad() {
      this.page++;
      this.getNewsList();
    },
    // load() {
    //   if (this.page == -1) return;
    //   this.page++;
    //   this.getNewsList();
    // },
    // 请求文章列表
    getNewsList() {
      let cateName = this.$route.name;
      get("/article/lists", { cateName: cateName, page: this.page })
        .then((res) => {
          if (res.data.artList.length == 0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          if (this.page == 1) {
            this.artList = res.data.artList;
          } else {
            this.artList = this.artList.concat(res.data.artList);
          }
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.loading = false;
        });
    },
    toNewsDetial(artId) {
      this.$router.push({ path: `/newsDetial/${artId}` })
    }
  },
  created() { },
  computed: {},
  mounted() {
    this.getNewsList();
  },

};
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.psyprose-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.psyprose-list {
  margin-top: 1rem;
  display: flex;
}

.psyprose-box:last-child {
  margin-bottom: 2.8rem;
}

.psyprose-box::-webkit-scrollbar {
  /*隐藏滚动条*/
  display: none;
}

.psyprose-list-text {
  display: flex;
  align-items: center;
  width: 9rem;
  padding-left: 0.5rem;
}

.psyprose-list-text>div {
  width: 100%;
}

.psyprose-list-img>img {
  height: 100%;
  width: 7.1rem;
  border-radius: .3rem;
}

.psyprose-list-text-title {
  // font-size: .75rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.psyprose-list-text-info {
  font-size: .7rem;
  font-weight: 400;
  color: #9B9DA2;
  padding-top: .5rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.no-content {
  text-align: center;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
}

.el-image {
  width: 8rem;
  height: 4.5rem;
  display: block;
  border-radius: 0.3rem !important;
}
</style>

