<template>
  <div class="box">
    <div class="banner">
      <img src="../assets/course/banner.png" alt="">
    </div>
    <div class="course-box">
      <div class="course-nav">
        <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }"
          v-for="cateDataItem in cateData" :key="cateDataItem.cateid" @click="tabChange(cateDataItem.cateId)">
          {{ cateDataItem.cateName }}</div>
      </div>
      <!-- 课程主体部分 -->
      <div class="learnCourse" v-infinite-scroll="load" style="overflow:auto;max-height: 30rem;width:100%">
        <div class="learnCourse-list" v-for="cataDataListItem in cataDataList" :key="cataDataListItem.courseId"
          @click="toClasses(cataDataListItem)">
          <div class="learnCourse-list-img">
            <el-image v-bind:src="cataDataListItem.coverImg" scroll-container=".learnCourse" lazy />
          </div>
          <div class="learnCourse-list-title">{{ cataDataListItem.courseTitle }}</div>
          <div class="learnCourse-list-text">
            <div class="hot-class-icon">
              <img src="../assets/home/hot-icon.png" alt="">
              <div>{{ cataDataListItem.views }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-content" v-if="!cataDataList.length">
        <img src="../assets/home/no-content.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import request from "../utils/request";
export default {
  data () {
    return {
      tabActive: 0,
      cataDataList: [],
      cateData: [],
      page: 1,
      total: 0,
      count: 1,
      cateId: 0
    };

  },
  methods: {
    load () {
      if (this.page == -1) return;
      this.page++;
      this.getCourseList();
    },
    //导航列表
    getCourseNav () {
      // get("/course/cates").then((res) => {
      //   this.cateData = res.data
      // }).catch(err => {
      //   console.log(err)
      // })
      let data = {};
      request({
        url: "/course/cate",
        method: "post",
        data,
      }).then((res) => {
        this.cateData = res.data;
        this.cateData.unshift({ cateId: 0, cateName: '全部' })
      });
    },
    //课程列表
    getCourseList () {
      // get("/course/lists", { cateId: this.cateId, page: this.page, count: 1 }).then((res) => {
      //   this.cataDataList.push(...res.data.lists)
      //   this.total = res.data.total
      // }).catch(err => {
      //   console.log(err)
      // })
      let data = {
        cateId: this.cateId,
        pageNum: this.page,
        count: 1,
      };
      request({
        url: "/course/lists",
        method: "post",
        data: data,
      }).then((res) => {
        this.cataDataList.push(...res.data.lists)
        this.total = res.data.count;
      })
    },
    tabChange (cateId) {
      this.cateId = cateId
      this.page = 1
      this.cataDataList = []
      this.tabActive = cateId
      this.getCourseList(cateId)
    },
    toClasses (e) {
      // window.location.href = e
      window.open(`https://res.x-lingtong.cn/courseDetail/${e.courseId}`, '_blank');
    }
  },
  created () { },
  computed: {},
  mounted () {
    this.getCourseNav();
    this.getCourseList();
    // let siteConfig = getItem('centerConfig')
    // document.title = '课程学习 - ' + siteConfig.centerName;
  },
};
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.course-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.2rem;
}

.banner>img {
  width: 100%;
}

.course-nav {
  display: flex;
  margin-top: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  padding-left: 0.1rem;

  // 溢出部分剪切
  overflow: hidden;
  // 设置横向滚动
  overflow-x: scroll;
  // 禁止纵向滚动
  overflow-y: hidden;
  // 文本平铺
  text-align: justify;
  box-sizing: border-box;
  // 文本不允许换行
  white-space: nowrap;
}

.course-nav::-webkit-scrollbar {
  display: none;
}

.course-nav-list {
  margin-right: .25rem;
  padding: 0 .4rem;
  font-weight: 400;
  color: #3B3D41;
  font-size: .8rem;
  border-radius: .7rem;
  cursor: pointer;
}

.tabActive {
  color: #4676F8;
  border-radius: .7rem;
  box-shadow: 0 0 0 .05rem #4676F8;
  background: rgba(70, 118, 248, 0.05);
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: .3rem;
}

.learnCourse::-webkit-scrollbar {
  display: none;
}

.learnCourse-list {
  margin-bottom: 1.2rem;
  width: 7.8rem;
}

.learnCourse-list:last-child {
  padding-bottom: 2.9rem;
}

// .learnCourse-list-img>img {
//   width: 7.5rem;
//   height: 5rem;
//   border-radius: .3rem;
// }

.learnCourse-list-img>.el-image {
  border-radius: .3rem;
  height: 5.2rem;
}

.learnCourse-list-title {
  padding-top: .2rem;
  font-size: .8rem;
  font-weight: 600;
  color: #3B3D41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.learnCourse-list-text {
  padding-top: .5rem;
  font-weight: 400;
  color: #9B9DA2;
  font-size: .7rem;
}

.hot-class-icon {
  display: flex;
}

.hot-class-icon>img {
  width: .7rem;
  height: .7rem;
  padding-top: .05rem;
}

.no-content {
  text-align: center;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.el-image {
  display: block;
  min-height: 0.05rem;
}
</style>