<template>
  <div class="box">
    <div class="banner">
      <img src="../assets/cases/banner.png" alt="" />
    </div>

    <van-list @load="onLoad" v-model="loading" :finished="finished" finished-text="没有更多了">
      <div class="case-box" style="overflow: auto; max-height: 30rem; width: 100%">
        <div class="activityCenter-list" v-for="artListItem in artList" :key="artListItem.id"
          @click="toNewsDetial(artListItem.id)">
          <div class="activityCenter-list-img">
            <!-- <img v-bind:src="artListItem.imgUrl" /> -->
            <el-image v-bind:src="artListItem.imgUrl" scroll-container=".case-box" lazy />
          </div>
          <div class="activityCenter-list-text">
            <div>
              <div class="activityCenter-list-text-title">{{ artListItem.title }}</div>
              <p class="activityCenter-list-text-info">{{ artListItem.summary }}</p>
              <div class="time">{{ artListItem.createdAt }}</div>
            </div>
          </div>
        </div>
        <div class="no-content" v-if="!artList.length">
          <img src="../assets/home/no-content.png" alt="" />
        </div>
      </div>
    </van-list>

  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      artList: [],
      page: 1,
      loading: false,
      finished: true,
      refreshing: false
    }
  },
  methods: {
    onLoad() {
      this.page++;
      this.getNewsList();
    },
    // load() {
    //   if (this.page == -1) return
    //   this.page++
    //   this.getNewsList()
    // },
    // 请求文章列表
    getNewsList() {
      let cateName = this.$route.name
      get('/article/lists', { cateName: cateName, page: this.page })
        .then((res) => {
          // this.artList = res.data.artList
          if (res.data.artList.length == 0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          if (this.page == 1) {
            this.artList = res.data.artList;
          } else {
            this.artList = this.artList.concat(res.data.artList);
          }
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    // 跳转文章详情页
    toNewsDetial(artId) {
      this.$router.push({ path: `/newsDetial/${artId}` })
    },
  },
  created() { },
  computed: {},
  mounted() {
    this.getNewsList()
  },
}
</script>

<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0rem;
  padding: 0rem;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.news-box {
  padding: 0 1rem;
}

.activityCenter-box {
  padding: 0 1rem;
}

.banner {
  margin-top: 0.7rem;
}

.banner>img {
  width: 100%;
}

.case-box {
  padding: 0 1rem;
}

.activityCenter-list {
  // margin-top: 1.6rem;
  margin-top: 1rem;
  display: flex;
}

.activityCenter-list:last-child {
  padding-bottom: 1rem;
}

.activityCenter-box:last-child {
  margin-bottom: 2.8rem;
}

.activityCenter-list-text {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  width: 9rem;
}

.activityCenter-list-text>div {
  width: 100%;
}

.activityCenter-list-img>img {
  width: 7.1rem;
  height: 4.5rem;
  // background: plum;
  border-radius: 0.3rem;
}

.activityCenter-list-text-title {
  font-size: 0.75rem;
  font-weight: 600;
  color: #3b3d41;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.activityCenter-list-text-info {
  font-size: 0.7rem;
  font-weight: 400;
  color: #9b9da2;
  padding-top: 0.3rem;
  // 多行省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.time {
  width: 9.85rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: #9b9da2;
  margin-top: 0.3rem;
}

.no-content {
  text-align: center;
}

.no-content>img {
  width: 50%;
  height: 50%;
  margin-top: 3rem;
}

.el-image {
  display: block;
  // min-height: 0.05rem;
  // min-width: 0.05rem;
  width: 8rem;
  height: 4.5rem;
  border-radius: 0.3rem;
}
</style>